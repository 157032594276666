import React, { useState, useEffect } from "react";
import "./footer.css";
import { useNavigate, useLocation } from "react-router-dom";
import { MdOutlineFacebook } from "react-icons/md";
import { AiFillInstagram, AiFillTikTok } from "react-icons/ai";
import { FaFacebookSquare } from "react-icons/fa";

function Footer() {
  const [activeLink, setActiveLink] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const currentYear = new Date().getFullYear();

  const handleLinkClick = (link, path) => {
    setActiveLink(link);
    navigate(path); // Navega a la ruta especificada
  };

  useEffect(() => {
    const path = location.pathname;

    switch (path) {
      // case "/":
      //   setActiveLink("deporte");
      //   break;
      case "/athletes":
        setActiveLink("atletas");
        break;
      case "/coaches":
        setActiveLink("coaches");
        break;
      case "/events":
        setActiveLink("eventos");
        break;
      case "/fields":
        setActiveLink("campos");
        break;
      case "/records":
        setActiveLink("records");
        break;
      case "/executive-board":
        setActiveLink("board");
        break;
      case "/contact-us":
        setActiveLink("contacto");
        break;
      // case "/about-us":
      //   setActiveLink("nosotros");
      //   break;
      default:
        setActiveLink(""); // Restablecer si la ruta no coincide
    }
  }, [location.pathname, activeLink]);

  return (
    <footer>
      <div className="ContainerFooter d-none d-md-block">
        <ul className="footer-nav">
          {/* <li className={activeLink === "deporte" ? "active" : "textos"}>
            <a onClick={() => handleLinkClick("deporte", "/")}>Deporte</a>
          </li> */}
          <li className={activeLink === "atletas" ? "active" : "textos"}>
            <a onClick={() => handleLinkClick("atletas", "/athletes")}>Atletas</a>
          </li>
          <li className={activeLink === "coaches" ? "active" : "textos"}>
            <a onClick={() => handleLinkClick("coaches", "/coaches")}>Entrenadores</a>
          </li>
          <li className={activeLink === "eventos" ? "active" : "textos"}>
            <a onClick={() => handleLinkClick("eventos", "/events")}>Eventos</a>
          </li>
          <li className={activeLink === "campos" ? "active" : "textos"}>
            <a onClick={() => handleLinkClick("campos", "/fields")}>Campos</a>
          </li>
          <li className={activeLink === "records" ? "active" : "textos"}>
            <a onClick={() => handleLinkClick("records", "/records")}>Records</a>
          </li>
          <li className={activeLink === "board" ? "active" : "textos"}>
            <a onClick={() => handleLinkClick("board", "/executive-board")}>Consejo Directivo</a>
          </li>
          <li className={activeLink === "contacto" ? "active" : "textos"}>
            <a onClick={() => handleLinkClick("contacto", "/contact-us")}>Contacto</a>
          </li>
          {/* <li className={activeLink === "nosotros" ? "active" : "textos"}>
            <a onClick={() => handleLinkClick("nosotros", "/about-us")}>Nosotros</a>
          </li> */}
        </ul>
      </div>


      <div className="social-icons">
        <a href="https://www.facebook.com/ArcheryMexico/" target="blank_">
          <FaFacebookSquare />
        </a>
        <a href="https://www.instagram.com/worldarcherymexico/" target="blank_">
          <AiFillInstagram />
        </a>
        <a href="https://www.tiktok.com/@world.archery.mxi" target="blank_">
          <AiFillTikTok />
        </a>
      </div>

      <section>
        <div className="container">
          <div className="container-privacity">
            <div className="links-container-privacity">
              <a href="/privacy" className="link">
                AVISO DE PRIVACIDAD
              </a>
              {/* <a href="/terms-conditions" className="link">
                TÉRMINOS Y CONDICIONES
              </a> */}
            </div>
          </div>
        </div>
      </section>

      <div className="footer-text">
        <p>Ciudad de México, Hipódromo Condesa CP03100, México</p>
        <p>© {currentYear} World Archery Mexico | Todos los derechos reservados</p>
      </div>
    </footer>
  );
}

export default Footer;
