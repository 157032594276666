import React, { useState } from "react";
import { TbWorld } from "react-icons/tb";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";

const AcordionItem = ({ type_event, modalities, disciplines, logo, title, imgSrc, state_code, date, inscription_date, field_name, link_address, categories, documentacion, results }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const getEventDescription = () => {
    switch (type_event) {
      case "1":
        return "nacional";
      case "2":
        return "regional";
      case "3":
        return "estatal";
    }
  };

  const getDisciplinesDescription = () => {
    if (typeof disciplines !== "string") return "No definida";

    const disciplinesArray = disciplines.split(',').map(Number);
  
    return disciplinesArray.map(disciplines => {
      switch (disciplines) {
        case 1:
          return "Outdoor";
        case 2:
          return "Indoor";
        case 3:
          return "3-D";
        case 4:
          return "Field";
        default:
          return null;
      }
    }).filter(Boolean).join(", ");
  };

  const getModalitiesDescription = () => {
    if (typeof modalities !== "string") return "No definida";

    const modalitiesArray = modalities.split(',').map(Number);
  
    return modalitiesArray.map(modalities => {
      switch (modalities) {
        case 1:
          return "Recurvo";
        case 2:
          return "Compuesto";
        case 3:
          return "Barebow";
        default:
          return null;
      }
    }).filter(Boolean).join(", ");
  };
  
  

  return (
    <div className="accordion-item">
      <div className="accordion-header row pt-3 position-relative" onClick={toggleAccordion}>
        <div className="col-12 col-md-3 d-flex pb-2 justify-content-center">
          <img src={logo} alt={title} />
        </div>
        <div className="col-12 col-md-3 d-flex pb-2 justify-content-center">
          <h2 className="text-center">{title}</h2>
        </div>
        <div className="col-12 col-md-3 d-flex pb-2 justify-content-center">
          <div>
            <div className="d-flex pb-2 justify-content-center">
              <img className="state-icon" src={imgSrc} alt="icon" />
            </div>
            <h3 className="text-center">{state_code}</h3>
          </div>
        </div>
        <div className="col-12 col-md-3 d-flex pb-2 justify-content-center">
          <h6 className="text-center">{date}</h6>
        </div>
        <div className="">
          <IoIosArrowDown className={`arrow-position arrowEvent ${isOpen ? "open" : ""}`} />
        </div>
      </div>
      {isOpen && (
        <div className="accordion-content">
          <div className="row">
            <div className="col-12 col-md-3 pb-4 d-flex justify-content-center">
              <div className="tag-item1">
                <span>Evento {getEventDescription()}</span>
              </div>
            </div>
            <div className="col-12 col-md-3 pb-4 d-flex justify-content-center">
              <div className="tag-item2">
                <span>{getDisciplinesDescription()}</span>
              </div>
            </div>
            <div className="col-12 col-md-3 pb-4 d-flex justify-content-center">
              <div className="link-item">
                <a href={results} target="balnk">
                  <TbWorld />
                  <span className="ps-2">REGISTRO</span>
                </a>
              </div>
            </div>
            <div className="col-12 col-md-3 pb-4 d-flex justify-content-center">
              <div className="link-item">
                <a href={results} target="balnk">
                  <MdOutlineAlternateEmail />
                  <span className="ps-2">RESULTADOS</span>
                </a>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-3 pb-2 d-flex justify-content-center">
              <div className="item">
                <h3>Modalidades</h3>
                <span>{getModalitiesDescription()}</span>
              </div>
            </div>
            <div className="col-12 col-md-3 pb-2 d-flex justify-content-center">
              <div className="item">
                <h3>Ubicacion</h3>
                <span><a href={link_address} target="blank" rel="noopener noreferrer" className="links-text">{field_name}</a></span>
              </div>
            </div>
            <div className="col-12 col-md-3 pb-2 d-flex justify-content-center">
              <div className="item">
                <h3>Convocatoria</h3>
                <span><a href={documentacion} target="_blank" rel="noopener noreferrer" className="links-text">Ver documento</a></span>
              </div>
            </div>
            <div className="col-12 col-md-3 pb-2 d-flex justify-content-center">
              <div className="item">
                <h3>Límite de inscripción</h3>
                <span>{inscription_date}</span>
              </div>
            </div>
          </div>

          {/* <div className="info">
            <div className="item">
              <h1>Ubicacion</h1>
              <span><a href={s_address} target="blank">Nombre del Campo</a></span>
            </div>
            <div className="item">
              <h1>Tipo</h1>
              <span>Evento {type_event}</span>
            </div>
            <div className="item">
              <h1>Modalidades</h1>
              <span>{getModalitiesDescription()}</span>
            </div>
            <div className="item">
              <h1>Convocatoria</h1>
              <span><a href={documentacion} target="_blank" rel="noopener noreferrer">Ver documento</a></span>
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default AcordionItem;
