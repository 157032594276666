import React, { useEffect, useState } from "react";
import NavBar from "../../Components/navbar/navbar";
import "./coaches.css";
import { IoIosArrowDown } from "react-icons/io";
import Footer from "../../Components/footer/footer";
import Pagination from "./pagination";
import CoachesTargetInfo from "../../Components/coaches-target-info/coaches-target-info";
import coach_photo from "../../assets/imgCoaches/coach_photo.svg";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { FaChevronDown } from "react-icons/fa";

import imageAle from "../../assets/imgAthletes/aleVal.png";
import iconMedal from "../../assets/imgAthletes/MedalGold.svg";
import imageTest from "../../assets/imgAthletes/userGray.png";
import ScrollToTop from "../../Components/scrollToTop/scrollToTop";
import axios from "axios";

function Coaches() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchName, setSearchName] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterState, setFilterState] = useState("");
  const [viewCoaches, setViewCoaches] = useState(12);
  const [dataCoaches, setDataCoaches] = useState([]);

  const handleScroll = (e) => {
    e.preventDefault();
    const section = document.querySelector(".ourCoaches");
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handdleShowMoreCoaches = () => {
    setViewCoaches(viewCoaches + 12);
  };

  useEffect(() => {
    getCoaches();
  }, []);

  const getCoaches = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_BACK}/0a2b15b1a0d8d09e86d4b42d2012a091/athletes`
      );

      const filteredData = response.data.data.filter((person) =>
        person.id_roll.includes("Entrenador")
      );

      setDataCoaches(filteredData);
    } catch (error) {
      console.errpr(error);
    }
  };

  const filteredCoaches = dataCoaches.filter((coach) => {
    return (
      (searchName === "" ||
        `${coach.name} ${coach.last_name}`
          .toLowerCase()
          .includes(searchName.toLowerCase())) &&
      (filterStatus === "" || coach.trainer_level === filterStatus) &&
      (filterState === "" || coach.association_state === filterState)
    );
  });

  return (
    <>
      <NavBar />
      <ScrollToTop />
      <section className="coaches-hero">
        <div className="coaches-title">
          <h1>Coaches</h1>
        </div>
        <div className="contentParrafoCoaches">
          <p>
            Conoce a nuestros entrenadores certificados. Entrena con la
            seguridad de que un profesional te está guiando en cada paso.
          </p>
        </div>
        <div>
          <a
            href="#"
            className="scroll-down"
            address="true"
            onClick={handleScroll}
          >
            <IoIosArrowDown className="scroll-down-arrow" />
          </a>
        </div>
      </section>

      <section className="ourCoaches" id="ourCoaches">
        <div className="our_coaches_title">
          <h1>Nuestros coaches</h1>
        </div>

        <div className="content_parrafo_councilMembers">
          <div className="container">
            <p>
              La combinación de experiencia y conocimiento actualizado es clave
              para garantizar un entrenamiento seguro, efectivo y personalizado.
              Nuestros entrenadores certificados están en constante formación
              para maximizar tus capacidad sin comprometer tu bienestar.
            </p>
          </div>
        </div>

        {/* Formulario de filtros */}
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-4 col-sm-12 mb-3 d-flex justify-content-md-end justify-content-center">
              <div className="search-container">
                <FaMagnifyingGlass className="search-icon" />
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="Buscar por nombre"
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-12 mb-3 d-flex justify-content-center">
              <div className="select-container">
                <select
                  className="form-control select-filter"
                  value={filterState}
                  onChange={(e) => setFilterState(e.target.value)}
                >
                  <option value="">Todos los estados</option>
                  <option value="Aguascalientes">Aguascalientes</option>
                  <option value="Baja California">Baja California</option>
                  <option value="Baja California Sur">
                    Baja California Sur
                  </option>
                  <option value="Campeche">Campeche</option>
                  <option value="Chiapas">Chiapas</option>
                  <option value="Chihuahua">Chihuahua</option>
                  <option value="Ciudad de México">Ciudad de México</option>
                  <option value="Coahuila">Coahuila</option>
                  <option value="Colima">Colima</option>
                  <option value="Durango">Durango</option>
                  <option value="Estado de México">Estado de México</option>
                  <option value="Guanajuato">Guanajuato</option>
                  <option value="Guerrero">Guerrero</option>
                  <option value="HHidalgoGO">Hidalgo</option>
                  <option value="Jalisco">Jalisco</option>
                  <option value="Michoacán">Michoacán</option>
                  <option value="Morelos">Morelos</option>
                  <option value="Nayarit">Nayarit</option>
                  <option value="Nuevo León">Nuevo León</option>
                  <option value="Oaxaca">Oaxaca</option>
                  <option value="Puebla">Puebla</option>
                  <option value="Querétaro">Querétaro</option>
                  <option value="Quintana Roo">Quintana Roo</option>
                  <option value="San Luis Potosí">San Luis Potosí</option>
                  <option value="Sinaloa">Sinaloa</option>
                  <option value="Sonora">Sonora</option>
                  <option value="Tabasco">Tabasco</option>
                  <option value="Tamaulipas">Tamaulipas</option>
                  <option value="Tlaxcala">Tlaxcala</option>
                  <option value="Veracruz">Veracruz</option>
                  <option value="YYucatánUC">Yucatán</option>
                  <option value="Zacatecas">Zacatecas</option>
                  <option value="UNAM">UNAM</option>
                  <option value="IPN">IPN</option>
                </select>
                <FaChevronDown className="select-icon" />
              </div>
            </div>
            <div className="col-md-4 col-sm-12 mb-3 d-flex justify-content-md-start justify-content-center">
              <div className="select-container">
                <select
                  className="form-control select-filter"
                  value={filterStatus}
                  onChange={(e) => setFilterStatus(e.target.value)}
                >
                  <option value="">Certificación: Todos</option>
                  <option value="1">Certificación 1</option>
                  <option value="2">Certificación 2</option>
                  <option value="3">Certificación 3</option>
                  <option value="4">Certificación 4</option>
                  <option value="5">Certificación 5</option>
                  <option value="6">Certificación 6</option>
                  <option value="7">Certificación 7</option>
                  <option value="8">Certificación 8</option>
                  <option value="9">Certificación 9</option>
                  <option value="10">Certificación 10</option>
                </select>
                <FaChevronDown className="select-icon" />
              </div>
            </div>
          </div>
        </div>

        {/* Coaches list */}
        <div className="athletes-container-cards">
          {filteredCoaches.length === 0 ? (
            <h4>No hay coaches por mostrar</h4>
          ) : (
            filteredCoaches
              .slice(0, viewCoaches)
              .map((coach, index) => (
                <CoachesTargetInfo key={index} dataCoach={coach} />
              ))
          )}
        </div>

        {filteredCoaches.length > viewCoaches && (
          <div className="show-more">
            <button onClick={handdleShowMoreCoaches}>Mostrar más</button>
          </div>
        )}
      </section>

      <Footer />
    </>
  );
}

export default Coaches;
