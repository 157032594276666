import "./coaches-target-info.css";
import iconStatus from "../../assets/imgAthletes/icon-status-green.png";
import iconStatusFail from "../../assets/imgAthletes/icon-status-red.png";

function CoachesTargetInfo({ dataCoach }) {

  const getCertificationDescription = () => {
    switch (dataCoach.trainer_level) {
      case "1":
        return "Iniciación";
      case "2":
        return "Principiantes";
      case "3":
        return "Intermedio";
      case "4":
        return "Avanzado";
      case "5":
        return "Selección estatl";
      case "6":
        return "Selección Juvenil";
      case "7":
        return "Selección Nacional";
      case "8":
        return "Medallista Olímpico";
    }
  };

  return (
    <div className="target-coach-info">
      <div className="image-container">
        <img className="coach" src={dataCoach.photo} alt={`${dataCoach.name} ${dataCoach.last_name}`}/>
        <img className="icon-association" src={dataCoach.association_logo} alt={dataCoach.assoc_name} />

      </div>

      <div className="information">
        <h6>
          {`${dataCoach.name} ${dataCoach.last_name}`}
          {dataCoach.status === "1" && (
            <img className="icon" src={iconStatus} alt="" />
          )}
        </h6>
        <div className="d-flex justify-content-between">
          <p>Edad: {dataCoach.birthday} años</p>
          <p>{"Entrenador"}</p>
        </div>
        <button className="first">{dataCoach.association_state}</button>
        <button className="second">{dataCoach.club_name}</button>
        <button className="third">
          <span>{getCertificationDescription()}</span>
        </button>
        <div className="info-coach"></div>
      </div>
    </div>
  );
}

export default CoachesTargetInfo;
