import React, { useEffect, useState } from "react";
import NavBar from "../../Components/navbar/navbar";
import Footer from "../../Components/footer/footer";
import "./fields.css";
import { IoIosArrowDown } from "react-icons/io";
import FieldsTargetInfo from "../../Components/fields-target-info/fields-target-info";
import deportivoGaleana from "../../assets/imgFields/deportivo-hermanos-galeana.png";
import defaultField from "../../assets/imgFields/campos_placeholder.png";
import campoNano from "../../assets/imgFields/CampoNano.png";
import asocSudcali from "../../assets/imgFields/AsociacionSudcaliforniana.png";
import asocMexiquense from "../../assets/imgFields/asociacion_mexiquense.png";
import bgDefault from "../../assets/imgFields/backFields.png";
import bgCampoNano from "../../assets/imgFields/campo_nano_bg.png";
import bgExample from "../../assets/imgEvents/WA-Image.png";
import bgExample2 from "../../assets/imgCoaches/backCoaches.png";
import bgExample3 from "../../assets/imgAthletes/WA-woman.png";
//import AMAField from "../../assets/imgFields/AsociaciónMexiquensedeArquería.png"
import { FaMagnifyingGlass } from "react-icons/fa6";
import { FaAngleLeft, FaAngleRight, FaChevronDown } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import ScrollToTop from "../../Components/scrollToTop/scrollToTop";

export const dataFields = [
  {
    img: campoNano,
    bgImg: bgCampoNano,
    title: "Campo Nano",
    address:
      "Fernando Iglesias Calderón S/N, Venustiano Carranza, Jardín Balbuena, 15900 Ciudad De México, Cdmx",
    phone: "55 1234 5678",
    mail: "correo@correo.com",
    weekSchedule: "8:00 AM a 6:00 PM",
    sundaySchedule: "12:00 PM a 4:00 PM",
    iframeUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.031927412682!2d-99.11093792501325!3d19.41102644137!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff005788dd71%3A0xebc6eefa4ee32e7!2sCamp%20Nano%20-%20Tiro%20con%20Arco!5e0!3m2!1ses!2smx!4v1724097602581!5m2!1ses!2smx",
    urlMaps: "https://maps.app.goo.gl/P2NbteGdxJYtzBAy6",
  },
  {
    img: defaultField,
    bgImg: bgDefault,
    title: "Nombre",
    address: "Ubicación",
    phone: "00 0000 0000",
    mail: "correo@correo.com",
    weekSchedule: "8:00 AM a 6:00 PM",
    sundaySchedule: "12:00 PM a 4:00 PM",
  },
  {
    img: defaultField,
    bgImg: bgDefault,
    title: "Nombre",
    address: "Ubicación",
    phone: "00 0000 0000",
    mail: "correo@correo.com",
    weekSchedule: "8:00 AM a 6:00 PM",
    sundaySchedule: "12:00 PM a 4:00 PM",
  },
  {
    img: defaultField,
    bgImg: bgDefault,
    title: "Nombre",
    address: "Ubicación",
    phone: "00 0000 0000",
    mail: "correo@correo.com",
    weekSchedule: "8:00 AM a 6:00 PM",
    sundaySchedule: "12:00 PM a 4:00 PM",
  },
  {
    img: defaultField,
    bgImg: bgDefault,
    title: "Nombre",
    address: "Ubicación",
    phone: "00 0000 0000",
    mail: "correo@correo.com",
    weekSchedule: "8:00 AM a 6:00 PM",
    sundaySchedule: "12:00 PM a 4:00 PM",
  },
  {
    img: asocMexiquense,
    bgImg: bgExample,
    title: "Asociación Mexiquense de Arquería",
    address: "Baden Powell 1, 53150 Naucalpan De Juárez, Méx.",
    phone: "55 1234 5678",
    mail: "correo@correo.com",
    weekSchedule: "8:00 AM a 6:00 PM",
    sundaySchedule: "12:00 PM a 4:00 PM",
    iframeUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3761.1690332579965!2d-99.24051732501142!3d19.491362138802145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d2031c39b71f6f%3A0x20c61372fb7fc02e!2sAsociaci%C3%B3n%20Mexiquense%20de%20Arquer%C3%ADa%20A.C.!5e0!3m2!1ses!2smx!4v1724183819060!5m2!1ses!2smx",
    urlMaps: "https://maps.app.goo.gl/4Qw2nLPWqspkWAYu8",
  },
  {
    img: defaultField,
    bgImg: bgDefault,
    title: "Nombre",
    address: "Ubicación",
    phone: "00 0000 0000",
    mail: "correo@correo.com",
    weekSchedule: "8:00 AM a 6:00 PM",
    sundaySchedule: "12:00 PM a 4:00 PM",
  },
  {
    img: deportivoGaleana,
    bgImg: bgExample2,
    title: "Deportivo Hermanos Galeana",
    address:
      "Av. José Loreto Fabela 190, San Juan De Aragón Vii Secc, Gustavo A. Madero, 07910 Ciudad De México, Cdmx",
    phone: "55 1234 5678",
    mail: "correo@correo.com",
    weekSchedule: "8:00 AM a 6:00 PM",
    sundaySchedule: "12:00 PM a 4:00 PM",
    iframeUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3761.4837423860945!2d-99.07912062501171!3d19.47781293923582!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1fbb5d15e62d1%3A0x366056a3b0b1bf5e!2sDeportivo%20Hermanos%20Galeana!5e0!3m2!1ses!2smx!4v1724183990335!5m2!1ses!2smx",
    urlMaps: "https://maps.app.goo.gl/dEqeZQ8PkMCKvGKQ7",
  },
  {
    img: defaultField,
    bgImg: bgDefault,
    title: "Nombre",
    address: "Ubicación",
    phone: "00 0000 0000",
    mail: "correo@correo.com",
    weekSchedule: "8:00 AM a 6:00 PM",
    sundaySchedule: "12:00 PM a 4:00 PM",
  },
  {
    img: defaultField,
    bgImg: bgDefault,
    title: "Nombre",
    address: "Ubicación",
    phone: "00 0000 0000",
    mail: "correo@correo.com",
    weekSchedule: "8:00 AM a 6:00 PM",
    sundaySchedule: "12:00 PM a 4:00 PM",
  },
  {
    img: defaultField,
    bgImg: bgDefault,
    title: "Nombre",
    address: "Ubicación",
    phone: "00 0000 0000",
    mail: "correo@correo.com",
    weekSchedule: "8:00 AM a 6:00 PM",
    sundaySchedule: "12:00 PM a 4:00 PM",
  },
  {
    img: asocSudcali,
    bgImg: bgExample3,
    title: "Unidad Deportiva Asociación Sudcaliforniana",
    address: "Olímpico, 23640 Cd Constitución, B.C.S.",
    phone: "00 0000 0000",
    mail: "correo@correo.com",
    weekSchedule: "8:00 AM a 6:00 PM",
    sundaySchedule: "12:00 PM a 4:00 PM",
    iframeUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10454.768101327458!2d-111.66651151923327!3d25.043883331905977!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86b3a4b2a746883d%3A0x1a43a2d7d29f1f80!2sFovissste%20Olimpico%20Ol%C3%ADmpico%2C%2023640%20Cdad.%20Constituci%C3%B3n%2C%20B.C.S.!5e0!3m2!1ses!2smx!4v1724264877224!5m2!1ses!2smx",
    urlMaps: "https://maps.app.goo.gl/qzr4NHXzg4VM1m3f9",
  },
  {
    img: defaultField,
    bgImg: bgDefault,
    title: "Nombre",
    address: "Ubicación",
    phone: "00 0000 0000",
    mail: "correo@correo.com",
    weekSchedule: "8:00 AM a 6:00 PM",
    sundaySchedule: "12:00 PM a 4:00 PM",
  },
  {
    img: defaultField,
    bgImg: bgDefault,
    title: "Nombre",
    address: "Ubicación",
    phone: "00 0000 0000",
    mail: "correo@correo.com",
    weekSchedule: "8:00 AM a 6:00 PM",
    sundaySchedule: "12:00 PM a 4:00 PM",
  },
  {
    img: defaultField,
    bgImg: bgDefault,
    title: "Nombre",
    address: "Ubicación",
    phone: "00 0000 0000",
    mail: "correo@correo.com",
    weekSchedule: "8:00 AM a 6:00 PM",
    sundaySchedule: "12:00 PM a 4:00 PM",
  },
];

export const otherFields = [
  {
    img: deportivoGaleana,
    title: "Deportivo Hermanos Galeana",
    address:
      "Av. José Loreto Fabela 190, San Juan De Aragón Vii Secc, Gustavo A. Madero, 07910 Ciudad De México, Cdmx",
  },
  {
    img: asocMexiquense,
    title: "Asociación Mexiquense de Arquería",
    address: "Baden Powell 1, 53150 Naucalpan De Juárez, Méx.",
  },
  {
    img: asocSudcali,
    title: "Unidad Deportiva Asociación Sudcaliforniana",
    address: "Olímpico, 23640 Cd Constitución, B.C.S.",
  },
  {
    img: campoNano,
    title: "Campo Nano",
    address:
      "Fernando Iglesias Calderón S/N, Venustiano Carranza, Jardín Balbuena, 15900 Ciudad De México, Cdmx",
  },
  {
    img: defaultField,
    title: "Nombre",
    address: "Ubicación",
  },
  {
    img: defaultField,
    title: "Nombre",
    address: "Ubicación",
  },
  {
    img: defaultField,
    title: "Nombre",
    address: "Ubicación",
  },
  {
    img: defaultField,
    title: "Nombre",
    address: "Ubicación",
  },
  {
    img: defaultField,
    title: "Nombre",
    address: "Ubicación",
  },
];

function Fields() {
  const [viewFields, setViewFields] = useState(9);
  const [searchName, setSearchName] = useState("");
  const [selectedState, setSelectedState] = useState("");

  const [fields, setFields] = useState([]);

  const handleScroll = (e) => {
    e.preventDefault();
    const section = document.querySelector(".ourFields");
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handdleShowMoreFields = () => {
    setViewFields(viewFields + 9);
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_URL_BACK}/0a2b15b1a0d8d09e86d4b42d2012a091/fields`
      )
      .then((response) => {
        if (response.data.code === 1) {
          setFields(response.data.data); // Guardar los datos en el estado
        } else {
          console.error("Error al obtener los datos:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error en la solicitud:", error);
      });
  }, []);

  const filteredFields = fields.filter((field) => {
    return (
      (searchName === "" ||
        `${field.name}`.toLowerCase().includes(searchName.toLowerCase())) &&
      (selectedState === "" || field.state === selectedState)
    );
  });

  // Configuración del slider
  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <NavBar />
      <ScrollToTop />
      <section className="fields-hero">
        <div className="fields-title">
          <h1>Campos</h1>
        </div>
        <div className="contentParrafoFields">
          <p>
            Desde escuelas de iniciación hasta centros de alto rendimiento, aquí
            encontrarás los diversos campos donde se practica el tiro con arco
            en México.
          </p>
        </div>
        <div>
          <a
            href="#"
            className="scroll-down"
            address="true"
            onClick={handleScroll}
          >
            <IoIosArrowDown className="scroll-down-arrow" />
          </a>
        </div>
      </section>

      <section className="ourFields" id="ourFields">
        <div className="our_fields_title">
          <h2>Búsquedas populares</h2>
        </div>

        {/* Slider */}
        <div className="container mt-5">
          <Slider {...settings}>
            {fields.map((field, index) => (
              <div key={index} className="d-flex justify-content-center">
                <FieldsTargetInfo dataField={field} />
              </div>
            ))}
          </Slider>
        </div>

        {/* Formulario de filtros */}
        <div className="container custom-container mt-5 mb-5">
          <div className="row">
            <div className="col-md-6 col-12 mb-3 d-flex justify-content-center">
              <div className="search-container">
                <FaMagnifyingGlass className="search-icon" />
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="Buscar por nombre"
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                />
              </div>
            </div>

            <div className="col-md-6 col-12 mb-3 d-flex justify-content-center">
              <div className="select-container">
                <select
                  className="form-control select-filter"
                  value={selectedState}
                  onChange={(e) => setSelectedState(e.target.value)}
                >
                  <option value="">Estado: Todos</option>
                  <option value="Aguascalientes">Aguascalientes</option>
                  <option value="Baja California">Baja California</option>
                  <option value="Baja California Sur">
                    Baja California Sur
                  </option>
                  <option value="Campeche">Campeche</option>
                  <option value="Chiapas">Chiapas</option>
                  <option value="Chihuahua">Chihuahua</option>
                  <option value="Ciudad de México">Ciudad de México</option>
                  <option value="Coahuila">Coahuila</option>
                  <option value="Colima">Colima</option>
                  <option value="Durango">Durango</option>
                  <option value="Estado de México">Estado de México</option>
                  <option value="Guanajuato">Guanajuato</option>
                  <option value="Guerrero">Guerrero</option>
                  <option value="Hidalgo">Hidalgo</option>
                  <option value="Jalisco">Jalisco</option>
                  <option value="Michoacán">Michoacán</option>
                  <option value="Morelos">Morelos</option>
                  <option value="Nayarit">Nayarit</option>
                  <option value="Nuevo León">Nuevo León</option>
                  <option value="Oaxaca">Oaxaca</option>
                  <option value="Puebla">Puebla</option>
                  <option value="Querétaro">Querétaro</option>
                  <option value="Quintana Roo">Quintana Roo</option>
                  <option value="San Luis Potosí">San Luis Potosí</option>
                  <option value="Sinaloa">Sinaloa</option>
                  <option value="Sonora">Sonora</option>
                  <option value="Tabasco">Tabasco</option>
                  <option value="Tamaulipas">Tamaulipas</option>
                  <option value="Tlaxcala">Tlaxcala</option>
                  <option value="Veracruz">Veracruz</option>
                  <option value="Yucatán">Yucatán</option>
                  <option value="Zacatecas">Zacatecas</option>
                  <option value="UNAM">UNAM</option>
                  <option value="IPN">IPN</option>
                </select>
                <FaChevronDown className="select-icon" />
              </div>
            </div>
          </div>
        </div>

        {/* Targets de campos */}
        <div className="container custom-container">
          <div className="row">
            {filteredFields.slice(0, viewFields).map((field, index) => (
              <div
                className="col-md-4 d-flex justify-content-center mb-5"
                key={index}
              >
                <FieldsTargetInfo dataField={field} />
              </div>
            ))}
          </div>
        </div>

        {filteredFields.length > viewFields && (
          <div className="show-more">
            <button onClick={handdleShowMoreFields}>Mostrar más</button>
          </div>
        )}
      </section>

      <Footer />
    </>
  );
}

// Componente para la flecha derecha del slider
function SampleNextArrow({ onClick }) {
  return (
    <div className="arrow arrow-right" onClick={onClick}>
      <FaAngleRight />
    </div>
  );
}

// Componente para la flecha izquierda del slider
function SamplePrevArrow({ onClick }) {
  return (
    <div className="arrow arrow-left" onClick={onClick}>
      <FaAngleLeft />
    </div>
  );
}

export default Fields;
