import React from "react";
import "./style.css";

import NavBar from "../../Components/navbar/navbar";
import Footer from "../../Components/footer/footer";
import CustomCollapse from "../../Components/Collapse/Collapse";
import DownButton from "../../Components/down-botton/down-button";
import ScrollToTop from "../../Components/scrollToTop/scrollToTop";

const TermsAndConditions = () => {
  return (
    <>
      <NavBar />
      <ScrollToTop />
      <div className="terms-hero">
        <h1>Términos y condiciones</h1>
        <DownButton element="terms-content" />
      </div>

      <div className="terms-content">
        <div className="terms-content-description">
          <p>
            Lorem ipsum dolor sit amet consectetur. Nibh lectus volutpat morbi
            sit fusce sed augue pretium sit. Sodales ullamcorper egestas
            senectus viverra nisi feugiat ipsum aliquet. Tempus lorem urna
            facilisis lacus id amet volutpat scelerisque lacus. Molestie turpis
            sit malesuada auctor feugiat consequat sit. Risus ac posuere
            ullamcorper id.
          </p>
        </div>
        <div className="terms-content-description">
          <p>
            Lorem ipsum dolor sit amet consectetur. Nibh lectus volutpat morbi
            sit fusce sed augue pretium sit. Sodales ullamcorper egestas
            senectus viverra nisi feugiat ipsum aliquet. Tempus lorem urna
            facilisis lacus id amet volutpat scelerisque lacus. Molestie turpis
            sit malesuada auctor feugiat consequat sit. Risus ac posuere
            ullamcorper id.
          </p>
        </div>

        <div className="terms-content-collapse">
          <div className="left-column">
            <CustomCollapse title="Título 1">
              <p>
                Vitae congue eu consequat ac felis placerat vestibulum lectus
                mauris ultrices. Cursus sit amet dictum sit amet justo donec
                enim diam porttitor lacus luctus accumsan tortor posuere.
              </p>
            </CustomCollapse>
            <CustomCollapse title="Título 2">
              <p>
                Vitae congue eu consequat ac felis placerat vestibulum lectus
                mauris ultrices. Cursus sit amet dictum sit amet justo donec
                enim diam porttitor lacus luctus accumsan tortor posuere.
              </p>
            </CustomCollapse>
            <CustomCollapse title="Título 3">
              <p>
                Vitae congue eu consequat ac felis placerat vestibulum lectus
                mauris ultrices. Cursus sit amet dictum sit amet justo donec
                enim diam porttitor lacus luctus accumsan tortor posuere.
              </p>
            </CustomCollapse>
            <CustomCollapse title="Título 4">
              <p>
                Vitae congue eu consequat ac felis placerat vestibulum lectus
                mauris ultrices. Cursus sit amet dictum sit amet justo donec
                enim diam porttitor lacus luctus accumsan tortor posuere.
              </p>
            </CustomCollapse>
            <CustomCollapse title="Título 5">
              <p>
                Vitae congue eu consequat ac felis placerat vestibulum lectus
                mauris ultrices. Cursus sit amet dictum sit amet justo donec
                enim diam porttitor lacus luctus accumsan tortor posuere.
              </p>
            </CustomCollapse>
            <CustomCollapse title="Título 6">
              <p>
                Vitae congue eu consequat ac felis placerat vestibulum lectus
                mauris ultrices. Cursus sit amet dictum sit amet justo donec
                enim diam porttitor lacus luctus accumsan tortor posuere.
              </p>
            </CustomCollapse>
          </div>
          <div className="right-colum">
            <CustomCollapse title="Título 1">
              <p>
                Vitae congue eu consequat ac felis placerat vestibulum lectus
                mauris ultrices. Cursus sit amet dictum sit amet justo donec
                enim diam porttitor lacus luctus accumsan tortor posuere.
              </p>
            </CustomCollapse>
            <CustomCollapse title="Título 2">
              <p>
                Vitae congue eu consequat ac felis placerat vestibulum lectus
                mauris ultrices. Cursus sit amet dictum sit amet justo donec
                enim diam porttitor lacus luctus accumsan tortor posuere.
              </p>
            </CustomCollapse>
            <CustomCollapse title="Título 3">
              <p>
                Vitae congue eu consequat ac felis placerat vestibulum lectus
                mauris ultrices. Cursus sit amet dictum sit amet justo donec
                enim diam porttitor lacus luctus accumsan tortor posuere.
              </p>
            </CustomCollapse>
            <CustomCollapse title="Título 4">
              <p>
                Vitae congue eu consequat ac felis placerat vestibulum lectus
                mauris ultrices. Cursus sit amet dictum sit amet justo donec
                enim diam porttitor lacus luctus accumsan tortor posuere.
              </p>
            </CustomCollapse>
            <CustomCollapse title="Título 5">
              <p>
                Vitae congue eu consequat ac felis placerat vestibulum lectus
                mauris ultrices. Cursus sit amet dictum sit amet justo donec
                enim diam porttitor lacus luctus accumsan tortor posuere.
              </p>
            </CustomCollapse>
            <CustomCollapse title="Título 6">
              <p>
                Vitae congue eu consequat ac felis placerat vestibulum lectus
                mauris ultrices. Cursus sit amet dictum sit amet justo donec
                enim diam porttitor lacus luctus accumsan tortor posuere.
              </p>
            </CustomCollapse>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
