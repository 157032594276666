import React, { useEffect, useState } from "react";
import "./header-home.css";
import { IoIosArrowDown } from "react-icons/io";
import axios from "axios";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function HeaderHome() {
  const [notices, setNotices] = useState([]);

  const handleScroll = (e) => {
    e.preventDefault();
    const section = document.querySelector(".ourHome");
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_URL_BACK}/0a2b15b1a0d8d09e86d4b42d2012a091/notices`
      )
      .then((response) => {
        if (response.data.code === 1) {
          setNotices(response.data.data); // Guardar los datos en el estado
        } else {
          console.error("Error al obtener los datos:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error en la solicitud:", error);
      });
  }, []);

  const notice = notices[0];
  console.log(notice);

  if (!notice) {
    return <div>Cargando noticias...</div>; // Mostrar un mensaje de carga si no hay datos
  }

  return (
    <section className="">
      <Swiper
        modules={[Navigation, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        navigation
        pagination={false}
        autoplay={{ delay: 6000 }}
        loop={true}
        style={{
          paddingTop: "0",   // Elimina el padding superior
          paddingBottom: "0", // Elimina el padding inferior
          width: "100%",
          minHeight: "830px",
          margin: 0,
          padding: 0
        }}
      >
        {notices.map((notice, index) => (
          <SwiperSlide key={index} style={{
            width: "100%",
            minHeight: "830px",
            margin: 0,
            padding: 0
          }}>
            <div
              className="carousel-item-container"
              style={{
                backgroundImage: `url(${notice.image})`,
                width: "100%",
                minHeight: "830px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                padding: "5rem 1rem 2rem",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                position: "relative",
                zIndex: "1"
              }}
            >
              <div className="container">
                <div className="cont-home">
                  <div className="home-title">
                    <h1>{notice.title}</h1>
                  </div>

                  <div className="home-description">
                    <p>{notice.description}</p>
                  </div>

                  <div className="contentParrafoHome">
                    {/* <p>{notice.body}</p> */}
                    <div className="buttons-container">
                      {notice.btn_text && (
                        <a 
                          href={notice.btn_link ? notice.btn_link : notice.slug} 
                          target="_blank" 
                          className="button-header-home"
                          >
                          {notice.btn_text}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          

            <div>
              <a
                href="#"
                className="scroll-down-Home"
                address="true"
                onClick={handleScroll}
              >
                <IoIosArrowDown className="scroll-down-arrow" />
              </a>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
    
  );
}

export default HeaderHome;
