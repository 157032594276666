import React from "react";

import "./style.css";
import Imagen2 from "../../assets/imaAbout/imagen2.png";
import Imagen3 from "../../assets/imaAbout/imagen3.png";
import NavBar from "../../Components/navbar/navbar";
import Footer from "../../Components/footer/footer";
import { IoIosArrowDown } from "react-icons/io";
import DownButton from "../../Components/down-botton/down-button";
import ScrollToTop from "../../Components/scrollToTop/scrollToTop";

function AboutUs() {
  const handleScroll = (e) => {
    e.preventDefault();
    const section = document.querySelector(".about-us-info");
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <NavBar />
      <ScrollToTop />
      <section className="contOurAboutUs">
        <div className="aboutUs-title">
          <h1>Sobre nosotros</h1>
        </div>

        <div className="contentParrafoAboutUs">
          <p>
            Desarrollamos modelos innovadores que permitan impulsar a México hacia el liderazgo mundial en tiro con arco y garanticen el crecimiento continuo de nuestro deporte.
          </p>
        </div>

        <div>
          <a
            href="#"
            className="scroll-down"
            address="true"
            onClick={handleScroll}
          >
            <IoIosArrowDown className="scroll-down-arrow" />
          </a>
        </div>
      </section>
      
        <section className="aboutUs-info">
        <div className="container">
        <div className="about-us-info" id="about-us-info">
            <div className="about-us-info-content">
              <div className="about-us-info-row">
                <div className="left">
                  <img
                    src={Imagen2}
                    alt="Imagen"
                    className="about-us-info-image"
                  />
                </div>

                <div className="right">
                  <div className="row-title">
                    <h1>Sobre Nosotros</h1>
                    <p>World Archery México es la federación encargada de fomentar, promover y desarrollar el tiro con arco en el país. Somos los responsables de regir y regular el deporte en apego a los lineamientos internacionales. Somos miembros asociados de World Archery y World Archery Americas.</p>
                    <p>Formamos parte del Sistema Nacional de Cultura Física y Deporte (SINADE). Trabajamos de la mano y en coordinación con la CONADE, con las diversas asociaciones miembros y los diferentes Institutos Estatales de Deporte para desarrollar programas que fomenten la participación deportiva en todos los niveles. Nuestro compromiso es identificar y apoyar a talentos emergentes, organizar eventos deportivos de alta calidad y promover la activación física entre la comunidad.</p>
                  </div>
                  <div className="row pt-3">
                    <div className="col-12 col-md">
                      <h3>Organización</h3>
                      <ul>
                        <li>Asociaciones</li>
                        <li>Consejo Directivo</li>
                        <li>Comités</li>
                      </ul>
                    </div>

                    <div className="col-12 col-md">
                      <h3>Corporación</h3>
                      <ul>
                        <li>Plan operativo</li>
                        <li>Oficina</li>
                        <li>Eventos</li>
                        <li>Desarrollo</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>

      <section className="aboutUs-history">
        <div className="container">
          <div className="about-us-history">
            <div className="about-us-history-content">
              <div className="left">
                <h1>Deporte</h1>
                <p>
                  El tiro con arco es un deporte que exige precisión, concentración y control mental. Cada flecha disparada con precisión es el resultado de años de entrenamiento y dedicación. Desde sus orígenes como una habilidad de caza y combate, hasta su evolución en un deporte de alta competencia, el tiro con arco ha capturado la imaginación de generaciones, fusionando tradición y modernidad.
                </p>
                <p>
                  World Archery México es la federación mexicana encargada de regular las diversas disciplinas deportivas existentes, incluidas el tiro con arco al aire libre (target), bajo techo (indoor), campo (field), 3D, entre otras. 
                </p>
                <p>
                  Las competencias que organizamos además de destacan a los mejores atletas actúan como un espacio para el intercambio de conocimientos y experiencias, reforzando la cohesión y el crecimiento de la comunidad nacional de tiro con arco.
                </p>
              </div>
              <div className="right">
                <img src={Imagen3} alt="Imagen 3" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="aboutUs-mission">
        <div className="container">
          <div className="about-us-mision">
            <div className="about-us-mision-content">
              <div className="about-us-mision-row">
                <h1>Misión</h1>
                <p>
                  Fomentar, promover y practicar el tiro con arco en la república mexicana en las diversas disciplinas que existen.
                </p>
              </div>
              <div className="about-us-mision-row">
                <h1>Visión</h1>
                <p>
                  Ser la Federación líder en el país, reconocida por nuestros logros deportivos, crecimiento integral, desarrollo constante y profesionalismo.
                </p>
              </div>
              <div className="about-us-mision-row">
                <h1>Valores</h1>
                <p className="mb-0">Precisión</p>
                <p className="mb-0">Innovación</p>
                <p className="mb-0">Juego limpio</p>
                <p className="mb-0">Deporte para todos</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default AboutUs;
