import React from "react";
import "./style.css";

import NavBar from "../../Components/navbar/navbar";
import Footer from "../../Components/footer/footer";
import CustomCollapse from "../../Components/Collapse/Collapse";
import DownButton from "../../Components/down-botton/down-button";
import ScrollToTop from "../../Components/scrollToTop/scrollToTop";

const Privacy = () => {
  return (
    <>
      <NavBar />
      <ScrollToTop />
      <div className="privacy-hero">
        <h1>Aviso de Privacidad</h1>
        <div className="contentParrafoPrivacy">
          <p>
            Nos aseguramos de manejar tu información personal de manera responsable y segura. Conoce cómo recopilamos, utilizamos y protegemos tus datos personales.
          </p>
        </div>
        <DownButton element="privacy-content" />
      </div>

      <div className="privacy-content">
        <div className="privacy-content-description">
          <p className="my-3">
          En cumplimiento con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo “LFPD”), ponemos a su disposición el presente aviso de privacidad, y al respecto le informamos lo siguiente:
          </p>
        </div>

        <div className="privacy-content-collapse">
          <div className="left-column">
            <CustomCollapse title="Responsable de la protección de sus datos">
              <p>
                MUNDO DE ARQUERIA EN MEXICO A.C. (en lo sucesivo denominado WORLD ARCHERY MEXICO), con domicilio para oír y recibir notificaciones en Alfonso Reyer 68, Interior 3, Hipódromo Condesa, Alcaldía Cuauhtémoc, C.P. 061700, en la Ciudad de México, CDMX, es responsable del tratamiento y protección de sus datos personales.
              </p>
            </CustomCollapse>
            <CustomCollapse title="Información que recopilamos">
              <p>
                Podemos recopilar datos personales como nombre, CURP, fecha de nacimiento,  domicilio, número de teléfono, correo electrónico y detalles relacionados con tu participación en eventos y servicios ofrecidos.
              </p>
            </CustomCollapse>
            <CustomCollapse title="Uso de la Información">
              <p>
                Utilizamos tu información personal para:
                <ul>
                  <li>Gestionar tu participación en nuestros eventos deportivos.</li>
                  <li>Enviarte comunicaciones y avisos relevantes sobre nuestras actividades</li>
                  <li>Mejorar la experiencia del usuario en nuestra página web y actividades.</li>
                  <li>Cumplir con nuestras obligaciones legales y normativas.</li>
                </ul>
              </p>
            </CustomCollapse>
            <CustomCollapse title="Obtención de datos personales">
              <p>
                Para navegar por https://worldarcherymexico.com o sus redes sociales no es necesario que facilites ningún dato personal. Los casos en los que sí proporcionas tus datos personales son los siguientes:
                <ul>
                  <li>Al registrarte a alguna de nuestras competencias</li>
                  <li>Al afiliarte a algun club y/o asociaciones miembro de World Archery Mexico</li>
                  <li>Al contactar a través de los formularios de contacto o enviar un correo electrónico</li>
                </ul>
              </p>
            </CustomCollapse>
            <CustomCollapse title="Datos personales de terceros">
              <p>
                Para los datos personales de terceros que nos proporciona, usted deberá contar previamente con el consentimiento del titular de dichos datos e informarle el sitio en donde podrá encontrar y conocer el presente aviso de privacidad integral. World Archery Mexico cumplirá en todo momento con las disposiciones de la LFPD y su Reglamento en cuanto al tratamiento de datos personales obtenidos de forma indirecta.
              </p>
            </CustomCollapse>
          </div>
          <div className="right-colum">
            <CustomCollapse title="Finalidad del tratamiento de datos personales">
              <p>
                Cuando te conectas al sitio web o a cualquiera de sus redes sociales asociadas, para mandar un correo a World Archery Mexico, estás facilitando información de carácter personal de la que el responsable es World Archery Mexico. Esta información puede incluir datos de carácter personal como pueden ser tu dirección IP, nombre y apellidos, dirección física, dirección de correo electrónico, número de teléfono, y otra información. Al facilitar esta información, das tu consentimiento para que tu información sea recopilada, utilizada, gestionada y almacenada por World Archery Mexico solo como se describe en el aviso de privacidad.

                Los datos personales y la finalidad del tratamiento por parte de World Archery Mexico es diferente según el sistema de captura de información:
                <ul>
                  <li>Formularios de contacto: World Archery Mexico solicita datos personales entre los que pueden estar: Nombre y apellidos, dirección de correo electrónico y número de teléfono con la finalidad de responder a tus consultas.</li>
                  <li>Formularios de registro: World Archery Mexico solicita los siguientes datos personales: Nombre completo, CURP, fecha de nacimiento, domicilio, número de teléfono y correo electrónico, con la finalidad de cumplir con lo establecido en lo dispuesto en la Ley General de Cultura Física y Deporte, su Reglamento, los lineamientos que para tal efecto emita y los demás ordenamientos jurídicos que resulten aplicables</li>
                </ul>
                Existen otras finalidades por las que World Archery Mexico trata tus datos personales:
                <ul>
                  <li>Para apoyar y mejorar los servicios que ofrece este sitio Web</li>
                  <li>Para analizar la navegación World Archery Mexico recoge otros datos no identificativos que se obtienen mediante el uso de cookies que se descargan en tu ordenador cuando navegas por el sitio Web</li>
                  <li>Para gestionar las actividades de marketing digital, World Archery Mexico tiene presencia en redes sociales. Si te haces seguidor en las redes sociales de World Archery Mexico el tratamiento de los datos personales se regirá por este apartado, así como por aquellas condiciones de uso, políticas de privacidad y normativas de acceso que pertenezcan a la red social que proceda en cada caso y que has aceptado previamente.</li>
                </ul>
                Puedes consultar las políticas de privacidad de las principales redes sociales en estos enlaces:
                <ul>
                  <li>Facebook: <a href="https://www.facebook.com/privacy/policy/" target="blank">https://www.facebook.com/privacy/policy/</a></li>
                  <li>Instagram: <a href="https://help.instagram.com/155833707900388" target="blank">https://help.instagram.com/155833707900388</a></li>
                  <li>X (Twitter): <a href="https://x.com/es/privacy" target="blank">https://x.com/es/privacy</a></li>
                  <li>Linkedin: <a href="https://es.linkedin.com/legal/privacy-policy" target="blank">https://es.linkedin.com/legal/privacy-policy</a></li>
                </ul>
              </p>
            </CustomCollapse>
            <CustomCollapse title="Exactitud y veracidad de los datos personales">
              <p>
                Te comprometes a que los datos facilitados a World Archery Mexico sean correctos, completos, exactos y vigentes, así como a mantenerlos debidamente actualizados. Como Usuario del sitio Web eres el único responsable de la veracidad y corrección de los datos que remitas al sitio exonerando a World Archery Mexico de cualquier responsabilidad al respecto.
              </p>
            </CustomCollapse>
            <CustomCollapse title="Aceptación y consentimiento">
              <p>
                Como usuario del sitio Web, declaras haber sido informado de las condiciones sobre protección de datos de carácter personal, aceptas y consientes el tratamiento de los mismos por parte de World Archery Mexico en la forma y para las finalidades indicadas en esta Política de Privacidad.
              </p>
            </CustomCollapse>
            <CustomCollapse title="Revocabilidad">
              <p>
                Para ejercitar tus derechos de acceso, rectificación, cancelación, portabilidad y oposición tienes que enviar un correo electrónico a <a href="mailto:info@worldarcherymex.com?subject=Revocacion%20de%20derechos%20">info@worldarcherymex.com</a> junto con la prueba válida de tu identidad.
              </p>
            </CustomCollapse>
            <CustomCollapse title="Cambios en la Política de Privacidad">
              <p>
                World Archery Mexico se reserva el derecho a modificar la presente Política de Privacidad para adaptarla a novedades legislativas o jurisprudenciales, así como a prácticas de la industria.
                Estas políticas estarán vigentes hasta que sean modificadas por otras debidamente publicadas.
                Fecha de última actualización: 16 de agosto de 2024.
              </p>
            </CustomCollapse>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
