import './athletes-card.css';
import iconComment from '../../assets/imgHome/Icon-comment.svg';

function AthletesCard({img, name, place, text, color}) {

  return (
    <div className='athletes-card-container' style={{background: color}}>
      <div className='img-container'>
        <div className='overlay'></div>
        <img src={img} alt='' />
        <div className='description'>
          <p>{name}</p>
          <p>{place}</p>
        </div>
      </div>
      <div className='text-container'>
        <i className='icon'>
          <img src={iconComment} alt='icon' />
        </i>
        <p>{text}</p>
      </div>
    </div>
  )
}

export default AthletesCard;