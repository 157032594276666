import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { dataFields, otherFields } from "./fields";
import axios from "axios";
import './FieldDetails.css';

import NavBar from "../../Components/navbar/navbar";
import Footer from "../../Components/footer/footer";
import FieldsTargetInfo from "../../Components/fields-target-info/fields-target-info";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 

import { IoIosArrowDown } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { PiMapPinAreaFill } from "react-icons/pi";
import { BsFillTelephoneFill } from "react-icons/bs";
import { TbMailFilled } from "react-icons/tb";
import { AiFillClockCircle } from "react-icons/ai";
import { FaAngleLeft, FaAngleRight, FaClock, FaEnvelope, FaMapMarkedAlt, FaMapMarkerAlt, FaPhone } from "react-icons/fa";

import modalgallery1 from "../../assets/imgFields/modal_galeria.png";
import modalgallery2 from "../../assets/imgFields/modal_galeria2.png";
import modalgallery3 from "../../assets/imgFields/modal_galeria3.png";
import modalgallery4 from "../../assets/imgFields/modal_galeria4.png";
import logoMaps from "../../assets/imgFields/logo_googlemaps.svg";
import ScrollToTop from "../../Components/scrollToTop/scrollToTop";

const normalizeTitle = (name) => {
    return name.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');
};


function FieldDetails() {

    const[fields, setFields] = useState([]);

    useEffect(() => {
        axios
          .get(
            `${process.env.REACT_APP_URL_BACK}/0a2b15b1a0d8d09e86d4b42d2012a091/fields`
          )
          .then((response) => {
            if (response.data.code === 1) {
              const fields = response.data.data.map((field) => {
                // Separar los nombres de las fotos
                const photoNames = field.photos
                  ? field.photos.split('###').filter((photo) => photo) // Filtrar posibles valores vacíos
                  : [];
                return {
                  ...field,
                  photoNames, // Añadir los nombres individuales de las fotos al objeto
                };
              });
              setFields(fields); // Guardar los datos en el estado
            } else {
              console.error('Error al obtener los datos:', response.data);
            }
          })
          .catch((error) => {
            console.error('Error en la solicitud:', error);
          });
      }, []);

    const { fieldName } = useParams();

    console.log("fieldName:", fieldName);

    // Normaliza el campo de búsqueda
    const normalizedFieldName = decodeURIComponent(fieldName).toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');

    // Encuentra el campo usando la normalización
    const field = fields.find(field => normalizeTitle(field.name) === normalizedFieldName);

    if (!field) {
        return <h2></h2>;
    }

    const handleScroll = (e) => {
        e.preventDefault();
        const section = document.querySelector(".infoField");
        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: "smooth",
            });
        }
    };

    // Configuración del slider
    const settings = {
        dots: false,
        infinite: true,
        speed: 200,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

// Componente para la flecha derecha del slider
function SampleNextArrow( { onClick } ) {
    return (
        <div className="arrow arrow-right" onClick={onClick}>
            <FaAngleRight />
        </div>
    );
}

// Componente para la flecha izquierda del slider
function SamplePrevArrow( { onClick } ) {
    return (
        <div className="arrow arrow-left" onClick={onClick}>
            <FaAngleLeft />
        </div>
    );
}

// Accede a las fotos del campo correspondiente
const fieldPhotos = field?.photoNames || []; 

    return (
        <>
            <NavBar />
            <ScrollToTop />

            <section 
                className="fields-hero"
                style={{
                    backgroundImage: `url(http://208.109.228.66/~wamexicobk/assets/images/photos_fields/${field.foto_portada})`,
                    width: "100%",
                    height: "830px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "2rem 1rem",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            >
                <div className="field-title">
                    <h2>{field.name}</h2>
                </div>
                <div>
                    <a href="#" className="scroll-down" address="true" onClick={handleScroll}>
                        <IoIosArrowDown className="scroll-down-arrow" />
                    </a>
                </div>
            </section>

            <section className="infoField mb-5" id="infoField">
                <div className="container px-3 px-md-0">
                    <div className="row d-flex justify-content-center pb-5">
                        <div className="col-12 col-md-6 col-lg-5 info-contact">
                            <h2 className="info-title">Información</h2>
                            <div className="info-text">
                                <li><FaMapMarkerAlt /></li>
                                <p>{field.address}</p>
                            </div>
                            <div className="info-text">
                            <li><FaMapMarkedAlt /></li>
                            <p>{field.state}</p>
                            </div>
                            <div className="info-text">
                            <li><FaPhone /></li>
                            <p>{field.contact_number}</p>
                            </div>
                            <div className="info-text">
                                <li><FaEnvelope /></li>
                                <p>{field.contact_email}</p>
                            </div>
                            <div className="info-text">
                                <li><FaClock /></li>
                                <div className="schedules">
                                    <p>Horarios</p>
                                    <p>{field.horario}</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-5 map-container text-center">
                            <div className="d-flex justify-content-center">
                                <iframe 
                                    src={`https://www.google.com/maps?q=${field.coords}&hl=es&z=16&output=embed`}
                                    width="80%" 
                                    height="400" 
                                    style={{ borderRadius: "20px", marginTop: "35px" }} 
                                    allowFullScreen="" 
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                            <a href={field.link_address} className="btn btn-primary google_maps_button" target="_blank">
                                <img src={logoMaps} alt="Google Maps" style={{ marginRight: '8px', width: '20px', height: '30px' }} />
                                Ir a Google Maps
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container text-center mb-5">
                <h2 className="gallery-title">Galería</h2>
                <div className="row justify-content-center mt-4">
                    {fieldPhotos.slice(0, 4).map((photo, index) => (
                    <div className="col-md-2 mb-3" key={index}>
                        <img
                        src={`http://208.109.228.66/~wamexicobk/assets/images/photos_fields/${photo}`}
                        alt={`Imagen ${index + 1}`}
                        className="img-fluid gallery-image"
                        />
                    </div>
                    ))}
                </div>
            </section>

            <section className="slider mb-5">
                <div className="other_fields_title">
                    <h2>Otros campos</h2>
                </div>

                {/* Slider */}
                <div className="container mt-5">
                    <Slider {...settings}>
                        {fields.map((field, index) => (
                            <div key={index} className="d-flex justify-content-center">
                                <FieldsTargetInfo dataField={field} />
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>

            <Footer />
        </>
    );
}

export default FieldDetails;